import "./App.css";

const EnvelopeIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
    className="w-6 h-6"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"
    />
  </svg>
);

function App() {
  return (
    <div className="App">
      <header className="text-center">
        <div className="flex justify-center">
          <div className="w-96 p-12">
            <img
              alt="app logic"
              src="logo-transparent-cropped.png"
              className="w-full h-full"
            />
          </div>
        </div>
        <div className="text-center">
          <span className="text-lg">
            Need a website or app for your business?
          </span>
          <br />
          <span className="text-2xl">We are here to help!</span>
        </div>
        <div className="mt-4 flex justify-center">
          <a href="mailto:applogic.in@gmail.com" className="hover:text-blue-500">
            <span className="flex">
              <EnvelopeIcon />
              <span className="ml-2">Contact Us</span>
            </span>
          </a>
        </div>
      </header>
    </div>
  );
}

export default App;
